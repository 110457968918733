import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  app: null,
  token: null,
  userId: null,
  name: null,
  lastname: null,
  email: null,
  expiration: null,
  phoneNumber: null,
  birthdate: null,
  gender: null,
  userObj: () => {},
  login: () => {},
  logout: () => {},
  updateUserData: () => {},
  logoutApp: () => {}
});
