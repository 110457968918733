import React, { useEffect, Suspense } from "react";
import TagManager from 'react-gtm-module';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { AuthContext } from "./context/auth-context.js";
import { useAuth } from "./hooks/auth-hook";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import LoadingSpinner from "./shared/components/LoadingSpinner";

const tagManagerArgs = {
  gtmId: 'GTM-5P2XWPV'
}
TagManager.initialize(tagManagerArgs)

const Home = React.lazy(() => import("./pages/Home"));
const HomeBussines = React.lazy(() => import("./pages/HomeBussines"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const Terms = React.lazy(() => import("./pages/Terms"));
const Prelogging = React.lazy(() => import("./pages/Prelogging"));
const Register = React.lazy(() => import("./pages/Register"));
const Login = React.lazy(() => import("./pages/Login"));
const ResetPasssword = React.lazy(() =>
  import("./user/resetPassword/ResetPassword")
);
//auth components
const SessionCheck = React.lazy(() => import("./pages/IsAuth"));
const Dashboard = React.lazy(() => import("./user/dashboard/Dashboard"));
const AcceptPermissions = React.lazy(() => import('./pages/accept_permissions'));
const Verify = React.lazy(() => import("./pages/Verify"));
const LogoutApp = React.lazy(() => import("./pages/Logout"));

const App = () => {
  
  const { app, token, userId, name, lastname, email, expiration, phoneNumber, userObj, login, logout, updateUserData } = useAuth();
  
  let routes;

  //console.log('-------- ' + typeof(token) + '-------------');
  //console.log(token);
  if (token) {
    routes = (
      <Switch>
        <Route path={["/","/home"]} exact render={() => <Redirect to="/dashboard" />}/>
        <Route path="/prelogging/:app?" exact>
          <Prelogging />
        </Route>
        <Route path="/prelogging" exact render={() => <Redirect to="/dashboard"/>}/>
        <Route path="/login" exact>
          <SessionCheck />
        </Route>
        <Route path="/signup" exact>
          <SessionCheck registro={true}/>
        </Route>
        <Route path="/accept-permission" exact>
          <AcceptPermissions />
        </Route>
        <Route
          path={[
            "/dashboard",
            "/profile",
            "/payment-types",
            "/notifications",
            "/activities",
            "/benefits",
            "/security",
            "/change-password",
            "/change-email",
            "/change-email-code",
            "/change-phone-number",
            "/change-phone-number-code",
          ]}
          exact
        >
          <Dashboard />
        </Route>
        <Route path="/autologin" component={Verify}/>   
        <Route path="/logout/:app?" exact component={LogoutApp}/>   
        {/* <Redirect to="/login" /> */}
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path={"/"} exact render={() => <Redirect to="/home" />}/>
        <Route path="/home" exact>
          <Home />
        </Route>
        <Route path="/password-reset" exact>
          <ResetPasssword />
        </Route>
        <Route path="/bussines" exact>
          <HomeBussines />
        </Route>
        <Route path="/privacy" exact>
          <Privacy />
        </Route>
        <Route path="/terms" exact>
          <Terms />
        </Route>
        <Route path="/prelogging/:app?" exact>
          <Prelogging />
        </Route>
        <Route path="/login" title="Ingresar" exact>
          <Login />
        </Route>
        <Route path="/signup/:app?" exact>
          <Register />
        </Route>
        <Route path="/autologin" component={Verify}/> 
        {/* <Redirect to="/home" /> */}
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        app: app,
        token: token,
        userId: userId,
        name: name,
        lastname: lastname,
        email: email,
        expiration: expiration,
        phoneNumber: phoneNumber,
        userObj: userObj,
        login: login,
        logout: logout,
        updateUserData:updateUserData
      }}
    >
      <Router>
        <ThemeProvider theme={theme}>
          <main>
            <Suspense
              fallback={
                <div className="center">
                  <LoadingSpinner />
                </div>
              }
            >
              {/* <ClientSocket username={token} room={app} /> */}
              {routes}
            </Suspense>
          </main>
        </ThemeProvider>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
