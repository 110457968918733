import { useState, useCallback, useEffect } from "react";
let logoutTimer;
export const useAuth = () => {
  const [app, setApp] = useState(null);
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(false);
  const [name, setName] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userObj, setUserObj] = useState(null);

  const login = useCallback(
    (
      app,
      token,
      userId,
      name,
      lastname,
      email,
      expiration,
      phoneNumber,
      userObj
    ) => {
      //console.group("auth-hoook");
      //console.log(phoneNumber);
      //console.groupEnd("auth-hoook");
      setApp(app);
      setToken(token);
      setUserId(userId);
      setName(name);
      setLastname(lastname);
      setEmail(email);
      let tokenExpiration = expiration;// || new Date(new Date().getTime() + 1000 * 60 * 60); //una hora
      setTokenExpiration(tokenExpiration);
      setPhoneNumber(phoneNumber);
      setUserObj(userObj);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          app: app,
          token: token,
          userId: userId,
          name: name,
          lastname: lastname,
          email: email,
          expiration: tokenExpiration.toISOString(),
          phoneNumber: phoneNumber,
          userObj: userObj,
        })
      );
    },
    []
  );

  const updateUserData = useCallback((userObj) => {
    console.group("profileUdate");
    //console.log(userObj?.birthdate);
    //console.log(new Date(userObj?.birthdate).getUTCDate());
    //console.log(new Date());
    // console.table(userObj);
    // console.groupEnd();
    setUserId(userObj?.id);
    setName(userObj?.name);
    setLastname(userObj?.lastname);
    setEmail(userObj?.email);
    setPhoneNumber(
        userObj?.cellphone
      /*userObj?.cellphone1 + "" + userObj?.cellphone2 + "" + userObj?.cellphone3*/
    );
    setUserObj(userObj);
  }, []);

  const logout = useCallback(() => {
    setApp(null);
    setToken(null);
    setUserId(null);
    setName(null);
    setLastname(null);
    setEmail(null);
    setTokenExpiration(null);
    setPhoneNumber(null);
    setUserObj(null);
    localStorage.removeItem("userData");
    //localStorage.removeItem("userRef");
  }, []);


  const logoutApp = useCallback((redirect) => {
    setApp(null);
    setToken(null);
    setUserId(null);
    setName(null);
    setLastname(null);
    setEmail(null);
    setTokenExpiration(null);
    setPhoneNumber(null);
    setUserObj(null);
    localStorage.removeItem("userData");
    window.location.href = redirect;
    //localStorage.removeItem("userRef");
  }, []);

  useEffect(() => {
    if (token && tokenExpiration) {
      const duration = tokenExpiration.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, duration);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpiration]);

  useEffect(() => {
     const storedData = JSON.parse(localStorage.getItem("userData"));
     if (
       storedData &&
       storedData.token &&
       new Date(storedData.expiration) > new Date()
     ) {
      login(
        storedData.app,
        storedData.token,
        storedData.userId,
        storedData.name,
        storedData.lastname,
        storedData.email,
        new Date(storedData.expiration),
        storedData.phoneNumber,
        storedData.userObj
      );
    }
  }, [login]);

  return {
    app,
    token,
    userId,
    name,
    lastname,
    email,
    tokenExpiration,
    phoneNumber,
    userObj,
    login,
    logout,
    updateUserData,
    logoutApp,
  };
};
