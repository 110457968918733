import { createMuiTheme} from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { caES } from "@material-ui/core/locale";

const breakpoints = createBreakpoints({});

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#2E3E4F",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#00B259",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#fff",
      },
      success: {
        main: "#00B259",
      },
      grayBackground: {
        main: "#F8F9FA",
      },
      grayText: "#A2A9B0",
    },
    typography: {
      fontFamily: ["Avenir", "sans-serif"].join(","),
      fontSize: 14,
      color: "red !important",
      h1: {
        fontSize: "1.928rem !important" /* 27px */,
        [breakpoints.up("sm")]: {
          fontSize: "2.857rem !important" /* 40px */,
        },
      },
      h2: {
        fontSize: "1.928rem !important" /* 27px */,
        [breakpoints.up("sm")]: {
          fontSize: "2.285rem !important" /* 32px */,
        },
      },
      h3: {
        fontWeight: 660,
        fontSize: "1.571rem !important" /* 22px */,
        [breakpoints.up("sm")]: {
          fontSize: "1.928rem !important" /* 27px */,
        },
      },
      h4: {
        fontSize: "1.285rem !important" /* 18px */,
        fontWeight: "bold !important",
        [breakpoints.up("sm")]: {
          fontSize: "1.571rem !important" /* 22px */,
          fontWeight: "bold !important",
        },
      },
      overrides: {
        MuiButton: {
          root: {
            height: "48px !important",
            fontSize: "1rem !important",
            [breakpoints.up("sm")]: {
              height: "57px !important",
              width: "300px !important",
              fontSize: "1rem !important",
              fontWeight: "bold",
              alignSelf: "center !important",
            },
          },
          MuiTypography: {
            root: {
              fontFamily: "Avenir",
            },
          },
        },
      },
    },
  },
  caES
);

export default theme;
